import request from '@/utils/request'

export function getVipLevelList(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/pageList',
    method: 'POST',
    data,
  })
}

export function updateVipLevelStatus(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/updateOnShelfById',
    method: 'POST',
    data,
  })
}

export function getVipLevelDetail(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/findListDetailById',
    method: 'POST',
    data,
  })
}

export function updateVipLevelItem(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/updateById',
    method: 'POST',
    data,
  })
}

export function addVipLevelItem(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/add',
    method: 'POST',
    data,
  })
}

export function updateVipLevelDescription(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/description/save',
    method: 'POST',
    data,
  })
}

export function getVipLevelDescription(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/description/findByBizLineId',
    method: 'POST',
    data,
  })
}

export function getVipLevelRules(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/config/findVipLevelConfigGroupList',
    method: 'POST',
    data,
  })
}

export function updateVipLevelRules(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/config/updateBatchGroupConfig',
    method: 'POST',
    data,
  })
}

export function checkConfigVipLevelRules(data) {
  return request({
    url: 'marketing-web-api/admin/vip/level/config/checkUpdateConfig',
    method: 'POST',
    data,
  })
}

export function getVipRightsGroupList(data) {
  return request({
    url: 'marketing-web-api/admin/rights/findVipRightsGroupList',
    method: 'POST',
    data,
  })
}
