<template>
  <div class="integral-type-list">
    <!-- 后期需求变更备用 -->
    <!-- <div class="search-bar">
      <span class="label">会员等级名称：</span>
      <el-input size="small" v-model="search.name" placeholder="请输入会员等级名称" clearable />

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList"> 查询 </el-button>
      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch"> 重置 </el-button>
    </div> -->

    <div>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="processItem({}, 1)">
        新增等级
      </el-button>
      <el-button size="small" style="margin: 0 0 0 12px" @click="processItem({}, 2)">
        设置等级规则
      </el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      class="table-ex"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
      size="small"
    >
      <el-table-column prop="vipLevelNameDict" label="会员等级"> </el-table-column>
      <el-table-column prop="vipLevelName" label="等级名称"> </el-table-column>
      <el-table-column prop="amountDescription" label="消费金额"> </el-table-column>
      <el-table-column prop="rightsNum" label="会员权益"> </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span>{{ `${scope.row.validPeriod}${scope.row.validPeriodUnit || '年'}` }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="vipNum" label="会员人数"> </el-table-column>

      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isOnShelf == 0 ? 'danger' : 'success'">
            {{ scope.row.isOnShelf == 0 ? '下架' : '上架' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="processItem(scope.row, 3)"> 编辑 </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 4)">
            {{ scope.row.isOnShelf == 0 ? '上架' : '下架' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="pageSizeChange"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>

    <!-- 后期需求变更备用 -->
    <el-dialog
      :title="editDialogTitle"
      :visible.sync="editDialogVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="90px"
    >
      <EditLevel v-if="editDialogVisible" :item="currentItem" @cancel="dialogCancel" />
    </el-dialog>
  </div>
</template>

<script>
import { getVipLevelList, updateVipLevelStatus } from '@/api/vipLevel'
import EditLevel from './components/EditLevel.vue'

export default {
  components: {
    EditLevel,
  },
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {},
      editDialogVisible: false,
      editDialogTitle: '会员等级',
      currentItem: {},
    }
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    processItem(row, type) {
      type = Number(type)
      const isOnShelf = Number(row.isOnShelf)
      if (type === 1 || type === 3) {
        this.editDialogVisible = true
        this.editDialogTitle = type === 1 ? '新增会员等级' : '编辑会员等级'
        this.currentItem = type === 1 ? {} : { ...row }
        return
      }

      if (type === 2) {
        this.$router.push({ name: 'vipRules' })
      }

      if (type === 4) {
        this.$confirm(`确认是否${isOnShelf === 0 ? '上架' : '下架'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            updateVipLevelStatus({
              data: {
                vipLevelId: row.vipLevelId,
                isOnShelf: isOnShelf === 1 ? 0 : 1,
              },
            })
              .then((res) => {
                if (res.success) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.error(err))
          })
          .catch((err) => console.error(err))
      }
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : ''
      })

      this.pageNumber = 1
      this.getList()
    },
    getList() {
      getVipLevelList(this.searchCondition)
        .then((res) => {
          const { content, total } = res.data || {}
          this.tableData = content || []
          this.total = Number(total || '0')
        })
        .catch((err) => console.error(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
    },
    gotoPage(row, name, type) {
      //   console.log(row, name, type)
      //   if (type == 1) this.$router.push({ name })
      //   if (type == 2) this.$router.push({ name, query: { merchantId: row.merchantId } })
    },
    openTab(url) {
      //   url && window.open(url.startsWith('http') ? url : 'https://' + url)
    },
    dialogCancel(isSubmit) {
      isSubmit && this.getList()
      this.editDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.integral-type-list {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table-ex {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
