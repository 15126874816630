<template>
  <el-form
    ref="EditLevelForm"
    :model="formData"
    :rules="rules"
    label-width="120px"
    label-position="right"
    size="small"
  >
    <el-form-item label="等级ID：">
      <el-input
        v-model="formData.vipLevelId"
        :disabled="true"
        placeholder="系统自动生成"
      ></el-input>
    </el-form-item>

    <el-form-item label="会员等级：" prop="vipLevel">
      <KeySelect
        :val.sync="formData.vipLevel"
        :req-key="isJPG ? 'jpg_vip_level' : 'dpll_vip_level'"
      />
    </el-form-item>

    <el-form-item label="等级名称：" prop="vipLevelName">
      <el-input
        v-model="formData.vipLevelName"
        placeholder="请输入等级名称"
        :maxlength="3"
        show-word-limit
      ></el-input>
    </el-form-item>

    <el-form-item label="会员有效期（年）：" prop="validPeriod">
      <el-input-number v-model="formData.validPeriod" :min="0" :max="100"></el-input-number>
    </el-form-item>

    <el-form-item label="消费金额：" prop="amountDescription">
      <el-input v-model="formData.amountDescription" placeholder="请输入消费金额区间"></el-input>
      <el-alert
        title="此处数据只做展示，不参与逻辑运算"
        type="info"
        :closable="false"
        style="margin-top: 10px"
      >
      </el-alert>
    </el-form-item>

    <el-form-item label="积分加速兑换：" prop="integralMultiple">
      <el-radio-group v-model.number="formData.integralMultiple">
        <el-radio :label="1">1倍</el-radio>
        <el-radio :label="1.1">1.1倍</el-radio>
        <el-radio :label="1.2">1.2倍</el-radio>
        <el-radio :label="1.3">1.3倍</el-radio>
      </el-radio-group>
    </el-form-item>

    <template v-if="formData.rightsGroupList && formData.rightsGroupList.length > 0">
      <h4>会员权益</h4>

      <el-form-item
        v-for="(itemData, index) in formData.rightsGroupList"
        :key="index"
        :label="itemData.rightsCategoryName"
      >
        <template v-if="itemData.rightCategoryId == 3">
          <div
            v-for="item2 in itemData.rightsList"
            :key="item2.vipLevelRightsId"
            style="margin-bottom: 10px"
          >
            <el-checkbox v-model="item2.selected"></el-checkbox>

            {{ item2.rightsName }}，<el-input-number
              v-model="item2.validPeriod"
              :min="1"
              :max="100"
            />
            {{ item2.validPeriodUnit || '年' }}，
            <el-input-number v-model="item2.validPeriodNum" :min="1" :max="100" />
            次。
          </div>
        </template>

        <template v-if="itemData.rightCategoryId != 3">
          <el-checkbox
            v-for="item2 in itemData.rightsList"
            :key="item2.vipLevelRightsId"
            v-model="item2.selected"
          >
            {{ item2.rightsName }}
          </el-checkbox>
        </template>
      </el-form-item>
    </template>

    <el-form-item label="">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="$emit('cancel', false)">取 消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  getVipLevelDetail,
  updateVipLevelItem,
  addVipLevelItem,
  getVipRightsGroupList,
} from '@/api/vipLevel'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        vipLevelId: '',
        vipLevelName: '',
        vipLevel: '',
        validPeriod: 0,
        amountDescription: '',
        integralMultiple: '',
        rightsGroupList: [],
      },
      rules: {
        vipLevelName: { required: true, message: '请输入等级名称', trigger: 'blur' },
        vipLevel: { required: true, message: '请选择会员等级', trigger: 'change' },
        validPeriod: { required: true, message: '请输入会员有效期', trigger: 'blur' },
        amountDescription: { required: true, message: '请输入消费金额区间', trigger: 'blur' },
        integralMultiple: { required: true, message: '请选择积分加速兑换', trigger: 'change' },
      },
    }
  },
  computed: {
    isJPG() {
      return this.$route.query.bizLineId ? Number(this.$route.query.bizLineId) === 2 : false
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.vipLevelId) {
          this.getDetail()
        } else this.getVipRightsList()
      },
    },
  },
  methods: {
    getDetail() {
      getVipLevelDetail({ data: this.item.vipLevelId })
        .then((res) => {
          Object.keys(res.data || {}).forEach((key) => {
            this.formData[key] = res.data[key]
          })

          this.formData.vipLevel = String(this.formData.vipLevel)
          this.formData.integralMultiple = Number(this.formData.integralMultiple || 0)

          this.formData.rightsGroupList.forEach((el) => {
            el.rightsList.forEach((d) => {
              d.selected = Boolean(d.selected)
            })
          })
        })
        .catch((err) => console.log(err.message || err))
    },
    submit() {
      this.$refs.EditLevelForm.validate((valid) => {
        if (!valid) return

        const formCopy = JSON.parse(JSON.stringify(this.formData))
        formCopy.rightsGroupList.forEach((el) => {
          el.rightsList.forEach((d) => {
            d.selected = d.selected ? 1 : 0
          })
        })

        const func = !this.formData.vipLevelId ? addVipLevelItem : updateVipLevelItem

        func({ data: formCopy })
          .then((res) => {
            if (!res.success) return
            this.$message.success('操作成功')
            this.$emit('cancel', true)
          })
          .catch((err) => console.log(err.message || err))
      })
    },
    getVipRightsList() {
      getVipRightsGroupList({ data: { isDisable: 0 } })
        .then((res) => {
          this.formData.rightsGroupList = [...(res.data || [])]
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped></style>
